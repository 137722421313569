jQuery(document).ready(function($) {
	$('.bcs-slider-wrapper:not(.one-slide)').slick({
		autoplay: true,
		autoplaySpeed: 4000,
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '0',
		initialSlide: 0,
		arrows: false,
		dots: true,
		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
		responsive: [
			{
				breakpoint: 900,
				settings: {
					centerMode: false,
					slidesToShow: 1,
				}
			},
		]
	});

}); /* end of as page load scripts */
